html {
  font-family: system-ui, sans-serif;
  font-size: 1.25rem;
}

body {
  margin: 0;
}

html, body { margin:0; padding:0; overflow:hidden }
svg.map { position: absolute; top:0; bottom:0; left:0; right:0;
	display: inline-block;
	vertical-align: middle;
	height: 100%;
	width: 100%;
}

#root {
	height: 100%;
	width: 100%;
}
.headline {
  margin: 0;
  xline-height: 0;
  position: absolute;
  top: 5px;
  left: 5px;
  right: 150px;
  color: #083C4F;
  background: rgba(255,255,255,0.5);
}
.headline h1 {
	font-size: 1.2em;
}

.footer {
  margin: 0;
  position: absolute;
  left: 15px;
  bottom: 15px;
  right: 5px;
  color: #083C4F;
  background: rgba(255,255,255,0.5);
}

.footer h2 {
	font-size: 0.7em;
}

svg {

}

.controls {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  margin: 1.25rem 0;
  text-align: center;
  transform: translateX(-50%);
}

.controls > button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  height: 2rem;
  width: 2rem;
  background: #083C4F;
  color: #fff;
  border-radius: 100%;
  border: 0;
}
.controls > button:first-child {
  margin-right: 0.25rem;
}
